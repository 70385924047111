// import './jquery.visible.min';
var maxHeight = 0;
var winHeight = 0;
var mainTop = 0;
var lightFieldHeightOrg = 0;
var lightfield = null;
var headerHeightOrg = 0;
var headerObj = null;
var headerLogo = null;
var headerLogoHeightOrg = 0;

$(document).ready(function() {
    lightfield = document.getElementById('light-field');
    headerObj = document.getElementById('header');
    headerLogo = document.getElementById('headerlogo').lastElementChild.lastElementChild;
    winHeight = $(window).height();
    mainTop = parseInt($('#main').css('padding-top'));
    setTimeout(function() {
        initContent();
    }, 100);
});

function initContent() {
    var iconname = '';
    var pfad = '';
    maxHeight = document.body.clientHeight;
    $('.fontawsome-icon').each(function() {
        iconname = $(this).data('iconname');
        pfad = $(this).children().attr('src');
        $(this).load(pfad);
    });
    lightFieldHeightOrg = parseInt(getComputedStyle(lightfield).height);
    headerHeightOrg = parseInt(getComputedStyle(headerObj).height);
    headerLogoHeightOrg = parseInt(getComputedStyle(headerLogo).height);
    parallax();
    $(window).on('scroll', function() {
        parallax();
        aniMenue();
        aniIlu();
    });
}

function aniIlu() {
    var offset = $(window).scrollTop() + $(window).height();

    if(offset >= ($('body').height() - 35)) {
        $('.ilu').addClass('visible');
    }
}

function aniMenue() {

    var scrollTop = window.scrollY || window.scrollTop || 0;

    var lightfieldHeightNew = lightFieldHeightOrg - (parseInt(scrollTop) / 8);
    var headerHeightNew = headerHeightOrg - (parseInt(scrollTop) / 8);
    var headerLogoNew = headerLogoHeightOrg - (parseInt(scrollTop) / 6);

    if(lightfieldHeightNew < 130)
        lightfieldHeightNew = 130;

    if(headerHeightNew < 210)
        headerHeightNew = 210;

    if(headerLogoNew < 180)
        headerLogoNew = 180;

    lightfield.style.height = lightfieldHeightNew+'px';
    headerObj.style.height = headerHeightNew + 'px';
    headerLogo.style.maxHeight = headerLogoNew+'px';
}

function parallax() {
    var objekte = document.getElementsByClassName('parallax-bilder');

    var i = 0;
    var beforeOverlayed = false;
    var elemente = [];

    Array.prototype.forEach.call(objekte, function (el) {
        if(el.dataset.position !== 'fixed') {
            // if(el.dataset.position === 'mid')
            //     el.firstElementChild.style.marginTop = '-25%';
            // if(el.dataset.position === 'top')
            //     el.firstElementChild.style.marginTop = '0%';

            var pic = el.lastElementChild;
            var winTop = el.getBoundingClientRect().top;
            var moveTop = 0;
            pic.dataset.abstandoben2 = winTop;
            if(0 > (winTop - winHeight)) {
                moveTop = (winTop - winHeight) / 4 * -1;

                if(el.dataset.position === 'bottom')
                     moveTop = (winTop - winHeight) / 4 * -1;

                if(moveTop > (pic.lastElementChild.outerHeight / 2))
                    moveTop = (pic.lastElementChild.outerHeight / 2);
            }

            pic.lastElementChild.style.bottom = '0px';

            var picdata = getComputedStyle(pic.lastElementChild);
            var picturedata = getComputedStyle(pic);

            var tmp1 = parseInt(parseInt(picdata.height) - moveTop);
            var tmp2 = parseInt(picturedata.height);
            pic.dataset.tmp1 = tmp1;
            pic.dataset.tmp2 = tmp2;
            if(tmp1 > tmp2)
                pic.lastElementChild.style.transform = 'translate3d(0px, ' + moveTop + 'px, 0px)';

        } // else {
            // el.firstElementChild.style.marginTop = '0%';
        // }
        i++;
    });
}